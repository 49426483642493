import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../../Components/Widget";
import ParseQuery from "../../Utils/ParseQuery";

class SocialLoginResultPage extends React.Component {
  state = {
    data: null
  };
  componentDidMount() {
    let { location, appActions, navActions } = this.props;
    let queryParams = ParseQuery(location.search);
    let data = JSON.parse(decodeURIComponent(queryParams.data));
    this.setState({ data });
    if (data.token) {
      window.localStorage.setItem("token", data.token);
      appActions.autoLogin().then(() => navActions.push("/"));
    }
  }

  render() {
    let { profile } = this.props;
    let { data } = this.props;
    return (
      <div>
        {profile
          ? "Login success"
          : `social-login-result:${JSON.stringify(data)}`}
      </div>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(SocialLoginResultPage)
);
